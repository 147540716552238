import StyledHome from "../components/styled/home.style";
import Layout from "../components/styled/layout";

const Home = () => {
  return (
    <Layout>
      <StyledHome>
        <img
          className="team-cup-logo"
          src={require("../assets/images/team-cup-with-text-469x434.png")}
          alt="team-cup logo"
        />
          <h2 className="download-header">Download de app in je favoriete app store!</h2>
        <div className="app-store-badges">
          <a
            href="https://apps.apple.com/us/app/team-cup/id6443500125"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require("../assets/images/app-store-badge.png")}
              alt="App Store"
              className="app-store"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=app.teamCup"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require("../assets/images/google-play-badge.png")}
              alt="Google Play Store"
              className="google-play-store"
            />
          </a>
        </div>
      </StyledHome>
    </Layout>
  );
};

export default Home;
